/* HTML element constants */
const menuToggleButton = document.querySelector('.menu__toggle-button') as HTMLButtonElement;
const menuContentArea = document.querySelector('#menu-content-area') as HTMLDivElement;
const menuList = document.querySelector('.menu__list') as HTMLUListElement | null;
const menuItems = document.querySelectorAll('.menu__item') as NodeListOf<HTMLLIElement>;
const subMenuToggleButtons = document.querySelectorAll('.menu__sub-menu-toggle-button') as NodeListOf<HTMLButtonElement>;
const toolMenuList = document.querySelector('.tool-menu__list') as HTMLUListElement;

/* Media query for desktop */
const desktopMediaQuery = window.matchMedia('(min-width: 1260px)');

/* Set aria controls on sub menu buttons */
const setControlledSubMenu = (button: HTMLButtonElement): void => {
  const menuItemContainer = button.parentElement as HTMLDivElement;
  const subMenu = menuItemContainer.nextElementSibling as HTMLDivElement;
  const subMenuId = subMenu.id;

  button.setAttribute('aria-controls', subMenuId);
};

/* Expand and minify buttons */
const expandButton = (button: HTMLButtonElement, buttonClass: string): void => {
  button.classList.add(`${buttonClass}--expanded`);
  button.setAttribute('aria-expanded', 'true');
};

const minifyButton = (button: HTMLButtonElement, buttonClass: string): void => {
  button.classList.remove(`${buttonClass}--expanded`);
  button.setAttribute('aria-expanded', 'false');
};

/* Open and close */
const getHeight = (element: HTMLDivElement): string => {
  element.style.display = 'block';

  const height = element.scrollHeight + 'px';
  element.style.removeProperty('display');
  return height;
};

const openMenu = (element: HTMLDivElement, openClass: string): void => {
  const elementHeight = getHeight(element);
  element.classList.add(openClass);
  element.style.height = elementHeight;

  if (element.parentElement?.classList.contains('menu__item') && desktopMediaQuery.matches) {
    document.body.classList.add('menu-is-open');
  }

  window.setTimeout(() => {
    element.removeAttribute('style');
  }, 150);
};

const closeMenu = (element: HTMLDivElement, openClass: string): void => {
  element.style.height = element.scrollHeight + 'px';

  window.setTimeout(() => {
    element.style.height = '0';
  }, 1);

  window.setTimeout(function () {
    element.classList.remove(openClass);
    element.removeAttribute('style');

    if (element.parentElement?.classList.contains('menu__item') && desktopMediaQuery.matches) {
      document.body.classList.remove('menu-is-open');
    }
  }, 150);
};

const closeOpenMenuItems = (): void => {
  const openMenuItems = document.querySelectorAll('.menu__item--open') as NodeListOf<HTMLLIElement>;

  if (openMenuItems.length > 0) {
    openMenuItems.forEach(item => {
      const toggleButton = item.querySelector('.menu__item-container .menu__sub-menu-toggle-button') as HTMLButtonElement;
      const subMenu = item.lastElementChild as HTMLDivElement;

      item.classList.remove('menu__item--open');
      minifyButton(toggleButton, 'menu__sub-menu-toggle-button--expanded');
      closeMenu(subMenu, 'sub-menu--open');
    });
  }
};

const openCurrentMenuItem = (): void => {
  const currentMenuItem: HTMLLIElement | null = document.querySelector('.menu__item--selected-parent');

  if (currentMenuItem) {
    const toggleButton = currentMenuItem.querySelector('.menu__item-container .menu__sub-menu-toggle-button') as HTMLButtonElement;

    currentMenuItem.classList.add('menu__item--open');
    expandButton(toggleButton, 'menu__sub-menu-toggle-button');
    currentMenuItem?.querySelector('.sub-menu')?.classList.add('sub-menu--open');
  }
};

// Close functions on desktop events
const closeMenuOnFocusOut = (event: FocusEvent, menuItem: HTMLLIElement): void => {
  if (event.relatedTarget instanceof Element && !menuItem.contains(event.relatedTarget)) {
    closeOpenMenuItems();
  }
};

const closeMenuOnEsc = (event: KeyboardEvent): void => {
  if (event.defaultPrevented) {
    return;
  }

  if (event.key === 'Escape') {
    closeOpenMenuItems();
  }
  event.preventDefault();
};

const closeMenuWhenClickingOutside = (event: MouseEvent): void => {
  if (event.target instanceof Element) {
    const clickedElement: Element = event.target;
    const headerContainer = document.querySelector('.header__container') as HTMLDivElement;

    if (headerContainer !== clickedElement && !headerContainer.contains(clickedElement)) {
      closeOpenMenuItems();
    }
  }
};

/* Toggle buttons */
const toggleMenuButton = (): void => {
  const buttonClass = 'menu__toggle-button';
  const buttonStateText = menuToggleButton.querySelector('.menu__toggle-button-state-text') as HTMLSpanElement;
  const buttonMenuText = menuToggleButton.querySelector('.menu__toggle-button-menu-text') as HTMLSpanElement;

  if (!menuToggleButton.classList.contains(`${buttonClass}--expanded`)) {
    expandButton(menuToggleButton, buttonClass);
    buttonStateText.textContent = menuToggleButton.dataset.closeMenuText!;
    buttonStateText.classList.remove('sr-only');
    buttonMenuText.classList.add('sr-only');
  } else {
    minifyButton(menuToggleButton, buttonClass);
    buttonStateText.textContent = menuToggleButton.dataset.openMenuText!;
    buttonStateText.classList.add('sr-only');
    buttonMenuText.classList.remove('sr-only');
  }
};

const toggleSubMenuButton = (button: HTMLButtonElement): void => {
  const buttonClass = "menu__sub-menu-toggle-button";
  const hiddenButtonText = button.querySelector('.sr-only') as HTMLSpanElement;

  if (button.classList.contains(`${buttonClass}--expanded`)) {
    minifyButton(button, buttonClass);
    hiddenButtonText.textContent = `${button.dataset.minimizeText} ${button.dataset.itemName}`;
  } else {
    expandButton(button, buttonClass);
    hiddenButtonText.textContent = `${button.dataset.expandText} ${button.dataset.itemName}`;
  }
};

/* Toggle menu content on smaller screens */
const toggleMenuContent = (): void => {
  toggleMenuButton();

  const openClass = 'menu__content-area--open';
  if (menuContentArea.classList.contains(openClass)) {
    closeMenu(menuContentArea, openClass);
  } else {
    openMenu(menuContentArea, openClass);
  }
};

menuToggleButton.addEventListener('click', toggleMenuContent);

/* Toggle sub menu */
const toggleSubMenu = (button: HTMLButtonElement): void => {
  const itemContainer = button.parentElement as HTMLDivElement;
  const item = itemContainer.parentElement as HTMLLIElement;
  const subMenu = itemContainer.nextElementSibling as HTMLDivElement;

  // Handling top layer
  if (item.classList.contains('menu__item')) {
    if (desktopMediaQuery.matches && !item.classList.contains('menu__item--open')) {
      closeOpenMenuItems();
    }

    item.classList.toggle('menu__item--open');
  }

  toggleSubMenuButton(button);
  const openClass = 'sub-menu--open';
  if (subMenu.classList.contains(openClass)) {
    closeMenu(subMenu, openClass);
  } else {
    openMenu(subMenu, openClass);
  }
};

const placeToolMenuAfterMenu = (): void => {
  if (menuList && toolMenuList) {
    menuList.after(toolMenuList);
  }
};

const placeToolMenuBeforeMenu = (): void => {
  if (menuList && toolMenuList) {
    menuList.before(toolMenuList);
  }
};

const setNavigation = (event: MediaQueryListEvent | MediaQueryList): void => {
  if (event.matches) {
    placeToolMenuAfterMenu();
    closeOpenMenuItems();
  } else {
    if (document.body.classList.contains('menu-is-open')) {
      document.body.classList.remove('menu-is-open');
    }
    placeToolMenuBeforeMenu();
    openCurrentMenuItem();
  }
};

/* Event listeners */
if (subMenuToggleButtons.length > 0) {
  subMenuToggleButtons.forEach(button => {
    setControlledSubMenu(button);
    button.addEventListener('click', (): void => {
      toggleSubMenu(button);
    });
  });
}

desktopMediaQuery.addEventListener('change', setNavigation);
setNavigation(desktopMediaQuery);

window.addEventListener('keyup', (event: KeyboardEvent): void => {
  if (desktopMediaQuery.matches) {
    closeMenuOnEsc(event);
  }
});

document.addEventListener('click', (event: MouseEvent): void => {
  if (desktopMediaQuery.matches) {
    closeMenuWhenClickingOutside(event);
  }
});

if (menuItems.length > 0) {
  menuItems.forEach(menuItem => {
    menuItem.addEventListener('focusout', (event: FocusEvent): void => {
      if (desktopMediaQuery.matches) {
        closeMenuOnFocusOut(event, menuItem);
      }
    });
  });
}