﻿// Set global variables
if (!window.KnowitOptimizelyFramework) {
  window.KnowitOptimizelyFramework = {
    QuickChannel: null,
    YouTube: null,
    BlockMediaScriptIsInitialized: false,
  };
}

// Imports
import "../../Features/Layout/Features/Header/Features/Menu/Scripts/menu";
import "../../Features/Layout/Features/Header/Features/Disturbance/Scripts/disturbance";
import "../../Features/Shared/Forms/Scripts/form-elements-main";